import React from "react"
import { setCurrentEvent, updateWebsite as updateWebsiteAction } from "context/actions"
import Spinner from "components/elements/Spinner"
import {
  Route, Switch, useHistory, useLocation, useRouteMatch,
} from "react-router-dom"
import * as api from "api/events"
import { eventPath, eventsPath } from "utilities/routes"
import ProtectedRoute from "components/ProtectedRoute"
import { putWebsite } from "api/websites"
import { infoToast } from "utilities/toasts"
import { Flex } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import useCurrentAccount from "services/useCurrentAccount"
import { UUID, INTEGER } from "utilities/strings"
import usePermissions from "services/permissions"
import { AccountUser, User } from "sharedTypes"
import { useAccountConfiguration } from "queries/users"
import useFeatureFlag from "services/featureFlags"
import Dashboard from "./Dashboard"
import Guests from "./Guests"
import Emails from "./Emails"
import Website from "./Website"
import Settings from "./Settings"
import Seating from "./Seating"
import WebsiteButtonForm from "./Website/Setup/Button/WebsiteButtonForm"
import Forms from "./Forms/Forms"
import Submissions from "./Submissions/Submissions"
import GuestPortal from "./GuestPortal/GuestPortal"
import Texts from "./Texts/Texts"

type Props = {
  currentUser: User
  accountUsers: AccountUser[]
}

const EventShow = ({ currentUser, accountUsers }: Props) => {
  const { path } = useRouteMatch()
  const currentAccount = useCurrentAccount()
  const history = useHistory()
  const location = useLocation()
  const background = location.state && location.state.background
  const queryClient = useQueryClient()
  const {
    canEditSeating,
    canViewEmails,
    canViewForm,
    canViewGuests,
    canViewSeating,
    canViewSettings,
    canViewWebsite,
    canViewSubmissions,
    canViewGuestPortal,
    canViewTexting,
  } = usePermissions()
  const {
    isLoading: accountConfigurationIsLoading,
    accountConfiguration,
  } = useAccountConfiguration()
  const { isLoading: eventIsLoading, data: currentEvent } = useCurrentEvent()
  const { texting: textingEnabled } = useFeatureFlag()

  if (eventIsLoading || accountConfigurationIsLoading) {
    return <Spinner />
  }

  if (!currentEvent?.id) {
    return null
  }

  const {
    counts,
    currency,
    enhanceEnabled,
    eventGroups,
    maxTables,
    nonTicketedGuestLimit,
    segments,
    tables,
    timezone,
    website,
    segmentsEnabled,
    guestGroupsEnabled,
  } = currentEvent

  const { emailEnabled, stripeAccounts } = currentAccount

  const updateEvent = (params) => api.putEvent(
    currentEvent.id,
    { ...currentEvent, ...params },
  ).then(({ data }) => {
    setCurrentEvent(queryClient, data, currentUser)
  })

  const updateWebsite = (params) => putWebsite(currentEvent.id, params)
    .then(({ data }) => {
      updateWebsiteAction(queryClient, currentEvent.id, data)
    })

  const cloneEvent = () => api.cloneEvent(currentEvent.id).then(() => {
    infoToast({ title: "Cloning event now. It will appear on your events page shortly." })
    history.push(eventsPath())
  })

  const deleteEvent = () => api.deleteEvent(currentEvent.id).then(() => {
    history.push(eventsPath())
  })

  return (
    <Flex direction="column" h="100vh">
      <Switch location={background || location}>
        <ProtectedRoute
          path={`${path}/guests/:guestId${UUID}?/:action?`}
          authorized={canViewGuests}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Guests
            counts={counts}
            currency={currency}
            segmentsEnabled={segmentsEnabled}
            enhanceEnabled={enhanceEnabled}
            groupsEnabled={guestGroupsEnabled}
            eventGroups={eventGroups}
            importLimit={nonTicketedGuestLimit}
            segments={segments}
            tables={tables}
            timezone={timezone}
            accountUsers={accountUsers}
            event={currentEvent}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/emails/:emailId${INTEGER}?/:action?`}
          authorized={canViewEmails}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Emails
            eventId={currentEvent.id}
            counts={counts}
            enabled={emailEnabled}
            updateEvent={updateEvent}
            currentUser={currentUser}
            accountUsers={accountUsers}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/seating/(tables)?/:tableId${INTEGER}?/:action(edit)?`}
          authorized={(params) => (params.location.pathname.endsWith("edit") ? canEditSeating : canViewSeating)}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Seating
            eventId={currentEvent.id}
            counts={counts}
            tables={tables}
            maxTables={maxTables}
          />
        </ProtectedRoute>
        {textingEnabled && (
          <ProtectedRoute
            path={`${path}/texts/:textMessageId?`}
            authorized={canViewTexting}
            redirectTo={eventPath(currentEvent.id)}
          >
            <Texts
              enabled={emailEnabled}
              event={currentEvent}
              subscription={currentAccount.subscription}
            />
          </ProtectedRoute>
        )}
        <ProtectedRoute
          path={`${path}/forms/:formId?`}
          authorized={canViewForm}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Forms
            event={currentEvent}
            counts={counts}
            updateEvent={updateEvent}
            accountConfiguration={accountConfiguration}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/submissions/:subpath?`}
          authorized={canViewSubmissions}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Submissions event={currentEvent} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/guest-portal/:subpath?`}
          authorized={canViewGuestPortal}
          redirectTo={eventPath(currentEvent.id)}
        >
          <GuestPortal
            event={currentEvent}
            account={currentAccount}
            accountConfiguration={accountConfiguration}
            updateEvent={updateEvent}
            accountUsers={accountUsers}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/website`}
          authorized={canViewWebsite}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Website
            event={currentEvent}
            updateWebsite={updateWebsite}
            currentUser={currentUser}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/settings`}
          authorized={canViewSettings}
          redirectTo={eventPath(currentEvent.id)}
        >
          <Settings
            event={currentEvent}
            accountUsers={accountUsers}
            updateEvent={updateEvent}
            cloneEvent={cloneEvent}
            deleteEvent={deleteEvent}
            stripeAccounts={stripeAccounts}
            currentUser={currentUser}
          />
        </ProtectedRoute>
        <Route path="">
          <Dashboard accountUsers={accountUsers} />
        </Route>
      </Switch>

      <ProtectedRoute
        path={`${path}/website/button`}
        authorized={canViewWebsite}
        redirectTo={eventPath(currentEvent.id)}
      >
        <WebsiteButtonForm
          website={website}
          updateWebsite={updateWebsite}
          onClose={() => history.go(-1)}
        />
      </ProtectedRoute>
    </Flex>
  )
}

export default EventShow
